import {InMemoryCache} from 'apollo-cache-inmemory'
import {createUploadLink} from 'apollo-upload-client'
import React, {useEffect, useState} from "react";
import {useAuth0} from "./react-auth0-spa";
import Admin from '../Admin';
import {setContext} from 'apollo-link-context';
import {ApolloClient} from "apollo-client"
import config from "./auth_config.json";
import {ApolloProvider} from "@apollo/react-hooks"

const apolloCache = new InMemoryCache()
let url = 'https://europe-west1-love2drive-a2648.cloudfunctions.net/graphql'
// if(config.url != null)
// 	url = "http://localhost:5001/love2drive-a2648/europe-west1/graphql"
const uploadLink = createUploadLink({
	uri: url,
	headers: {
		"keep-alive": "true"
	}
})

const Profile = () => {
	const {isAuthenticated, getTokenSilently, loading, user} = useAuth0();
	const [client, setClient] = useState();
	const getClient = async () => {
		try {
			return await getTokenSilently().then(response => {


				const authLink = setContext((_, {headers}) => {
					// return the headers to the context so httpLink can read them
					return {
						headers: {
							...headers,
							authorization: `Bearer ${response}`,
						}
					}
				});
				return new ApolloClient({
					cache: apolloCache,
					link: authLink.concat(uploadLink),
				})
			})

		} catch (error) {
			console.log("Error in getClient: ")
			console.error(error);
		}
	};
	useEffect(
		() => {

			if (isAuthenticated) {
				getClient()
					.then(res => {
						if (res !== undefined)
							setClient(res);
					})
					.catch(error => {
						console.log("Error found in authentication: ")
						console.warn(JSON.stringify(error, null, 2));
					});
			} else {
				setClient()
			}
		}, [isAuthenticated]
	)
	if (loading || !user) {
		return <div>Loading...</div>;
	}

	if (client !== undefined) {
		return (
			<ApolloProvider client={client}>
				<Admin/>
			</ApolloProvider>
		);
	} else {

		return <></>
	}
};

export default Profile;
