import './App.css';
import { Router, Switch } from "react-router-dom";
import Profile from "./auth0/Profile";
import history from "./auth0/history";
import PrivateRoute from "./auth0/PrivateRoute";
function App() {
  return (
      <div className="App">
        {/* Don't forget to include the history module */}
        <Router history={history}>
          <Switch>
            <PrivateRoute path="/" component={Profile} />
          </Switch>
        </Router>
      </div>
  );
}

export default App;
